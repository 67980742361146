<script>
import Layout from "@/router/layouts/main";
import { api } from "@/state/services";

export default {
  locales: {
    pt: {
    },
    es: {
    }
  },
  components: {
    Layout,
  },
  data() {
    return {
      mentorships: {
        loading: true,
        errored: false,
        empty: false,
        list: null,
      },
    };
  },
  methods: {
    getMentorships() {
      this.mentorships.loading = true
      this.mentorships.list = null

      api
        .get('mentorships')
        .then(response => {
          if (response.data.status == 'success') {
            this.mentorships.list = response.data.list
            this.mentorships.loading = false
          } else {
            this.mentorships.list = null
          }
        })
        .catch(error => {
          this.mentorships.errored = error
          this.mentorships.loading = false
        })
        .finally(() => {
          this.mentorships.loading = false
        })
    }
  },
  mounted() {
    this.getMentorships()
  }
};
</script>

<template>
  <Layout>
    <div class="page-title-box d-md-flex justify-content-between mb-3">
      <div>
        <h3>{{ t('Mentorias') }}</h3>
        <p></p>
      </div>
    </div>

    <div v-if="mentorships.loading" class="text-center">
      <b-spinner small class="ml-2 align-middle" variant="white" role="status"></b-spinner>
    </div>
    <div v-else-if="!mentorships.list || mentorships.list && mentorships.list.length == 0" class="card">
      <div class="card-body">
        <div class="text-center py-5">
          <i class="far fa-sad-cry font-size-24"></i><br>
          ainda não temos nenhuma mentoria disponível
        </div>
      </div>
    </div>
    <div v-else class="mentorships row">
      <div class="col-lg-3 col-md-4 mb-4" v-for="(row, id) in mentorships.list" :key="id">
        <router-link tag="a" :to="'/mentorships/' + row.id" class="mentorhip w-100 text-dark">
          <img class="mentorhip-cover" :src="row.cover" />
        </router-link>
      </div>
    </div>
  </Layout>
</template>

<style scoped>

.mentorhip-cover {
  border-radius: 15px;
  width: 100%;
}
</style>